




































































import Popover from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import {
  Role,
  useAddRoleAssigneeMutation,
  useGetSelectableRolesQuery,
  useRemoveRoleAssigneeMutation,
} from "@/graphql/types";
import { EditableKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  components: { Popover, Table },
  emits: ["assignedRole", "removedRole"],
  props: {
    orgId: {
      type: String,
      required: false,
    },
    assigneeRoles: {
      type: Array as PropType<Role[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const editable = injectStrict(EditableKey);

    // Data
    const show = ref(false);
    const { result, loading } = useGetSelectableRolesQuery();

    const addRoleAssigneeMutation = useAddRoleAssigneeMutation({});
    const removeRoleAssigneeMutation = useRemoveRoleAssigneeMutation({});

    // Functions
    async function addAssignee(role: Role) {
      var result = await addRoleAssigneeMutation.mutate({
        input: {
          organizationId: props.orgId,
          roleId: role.id,
        },
      });
      if (result?.errors == null) emit("assignedRole", role);
    }

    async function removeAssignee(role: Role) {
      var result = await removeRoleAssigneeMutation.mutate({
        input: {
          organizationId: props.orgId,
          roleId: role.id,
        },
      });
      if (result?.errors == null) emit("removedRole", role);
    }

    function isAssigned(role: Role) {
      return props.assigneeRoles.some((element) => element.id == role.id);
    }

    return {
      editable,
      show,
      loading,
      mutationLoading: computed(
        () =>
          addRoleAssigneeMutation.loading.value ||
          removeRoleAssigneeMutation.loading.value
      ),
      roles: useResult(result, [], (data) => data.roles.nodes),
      addAssignee,
      removeAssignee,
      fields: computed<TableField[]>(() => [
        { key: "attribute", filter: true, focus: true },
      ]),
      isAssigned,
    };
  },
});
