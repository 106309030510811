

































import { computed, defineComponent, toRef, watch } from "@vue/composition-api";
import { OrganizationKey } from "../symbols";
import {
  Role,
  useGetOrganizationAsigneeRolesQuery,
  useRemoveRoleAssigneeMutation,
} from "@/graphql/types";
import Table from "@/components/Table.vue";
import { TableField } from "@/types/TableField";
import Dropdown from "@/components/Dropdown.vue";
import IMenu from "@/interfaces/IMenu";
import Tooltip from "@/components/Tooltip.vue";
import useNavigateToApplication from "@/composables/navigation/useNavigateToApplication";
import useCanNavigate from "@/composables/useCanNavigate";
import useNavigateToRole from "@/composables/navigation/useNavigateToRole";
import AssignRolePopup from "@/views/Organizations/Components/AssignRolePopup.vue";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { EditableKey } from "@/symbols";
import useShowOption from "@/composables/options/useShowOption";
import useRemoveOption from "@/composables/options/useRemoveOption";

export default defineComponent({
  components: { Table, Dropdown, Tooltip, AssignRolePopup },
  setup(_, { root }) {
    const organization = injectStrict(OrganizationKey);
    const roles = toRef(organization, "assigneeRoles");
    const { result, loading, error } = useGetOrganizationAsigneeRolesQuery(
      () => ({ id: organization?.id ?? "" }),
      { fetchPolicy: "no-cache" }
    );

    const editable = injectStrict(EditableKey);
    const removeRoleAssigneeMutation = useRemoveRoleAssigneeMutation({});

    function addedRole(item: Role) {
      roles.value = [...(roles.value ?? []), item];
    }

    function removeRole(item: Role) {
      roles.value =
        organization.assigneeRoles?.filter(
          (element) => element?.id != item.id
        ) ?? [];
    }

    const removeAssignee = async (item: Role) => {
      var result = await removeRoleAssigneeMutation.mutate({
        input: {
          organizationId: organization.id,
          roleId: item.id,
        },
      });
      removeRole(item);
    };

    watch(
      () => useResult(result).value,
      (newValue) => {
        roles.value = newValue?.assigneeRoles ?? [];
      },
      { immediate: true }
    );

    return {
      roles,
      loading,
      result,
      error,
      organization,
      addedRole,
      removeRole,
      options: computed<IMenu<Role>[]>(() => [
        {
          ...useShowOption(root.$tc("role.title")),
          disabled: (item) => !useCanNavigate("Role", item),
          command: (item) => useNavigateToRole(root.$router, item?.id),
        },
        {
          ...useShowOption(root.$tc("application.title")),
          disabled: (item) => !useCanNavigate("Application", item?.application),
          command: (item) =>
            useNavigateToApplication(root.$router, item?.application?.id),
        },
        {
          ...useRemoveOption(root.$tc("organization.role_assignment")),
          disabled: () => !editable.value,
          command: (item) => (item != null ? removeAssignee(item) : null),
        },
      ]),
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          label: root.$tc("roles.fields.name"),
          filter: true,
        },
        {
          key: "application.name",
          label: root.$tc("roles.fields.application"),
          filter: true,
        },
      ]),
    };
  },
});
