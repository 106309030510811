












import Table from "@/components/Table.vue";
import useNavigateToApplication from "@/composables/navigation/useNavigateToApplication";
import useShowOption from "@/composables/options/useShowOption";
import useCanNavigate from "@/composables/useCanNavigate";
import {
  Application,
  useGetOrganizationApplicationsQuery,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { FullScreenKey } from "@/symbols";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent } from "@vue/composition-api";
import { OrganizationKey } from "../symbols";

export default defineComponent({
  components: { Table },
  setup(_, { root }) {
    const organization = injectStrict(OrganizationKey);
    const fullscreen = injectStrict(FullScreenKey);

    const { result, loading } = useGetOrganizationApplicationsQuery(
      {
        id: organization.id,
      },
      { fetchPolicy: "no-cache" }
    );

    // all applications from the organization and from the assignee roles
    const applications = computed(() => {
      const applicationsFromOrg =
        useResult(result, null, (data) => data.organization.applications)
          .value ?? [];
      const applicationsFromAssigneeRoles =
        useResult(result, null, (data) =>
          data.organization.assigneeRoles.flatMap((x) => x.application)
        ).value ?? [];

      // returns distinct applications
      return (
        applicationsFromOrg
          ?.concat(applicationsFromAssigneeRoles)
          .filter(
            (application, index, self) =>
              self
                .map((application) => application?.id)
                .indexOf(application?.id) === index
          ) || []
      );
    });

    return {
      organization,
      loading,
      items: applications,
      fields: computed<TableField[]>(() => [
        {
          label: root.$tc("applications.fields.name"),
          key: "name",
          sortable: true,
          filter: true,
        },
        {
          label: root.$tc("applications.fields.description"),
          key: "description",
          sortable: true,
          filter: true,
        },
        ...(fullscreen.value
          ? [
              {
                label: root.$tc("applications.fields.organization"),
                key: "ownerOrganization.name",
                sortable: true,
                filter: true,
              },
            ]
          : []),
      ]),
      menuItems: computed<IMenu<Application>[]>(() => [
        {
          ...useShowOption(root.$tc("application.title")),
          disabled: (item) => !useCanNavigate("Application", item),
          command: (item) => useNavigateToApplication(root.$router, item?.id),
        },
      ]),
    };
  },
});
