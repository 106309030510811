import { Organization, useCreateOrganizationMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { Ref } from "@vue/composition-api";
import { ParentQueryKey } from "../symbols";

export default function (
  organization: Organization,
  logoFile: Ref<File | null>
) {
  const mutation = useCreateOrganizationMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    return mutation.mutate(
      {
        input: {
          name: organization.name ?? "",
          description: organization.description,
          vat: organization.vat ?? "",
          logoFile: logoFile.value,
          organizationContacts:
            organization.organizationContacts?.map((element) => {
              return {
                name: element?.name ?? "",
                phone: element?.phone ?? "",
                email: element?.email ?? "",
              };
            }) ?? [],
        },
      },
      {
        context: {
          useMultipart: true,
        },
        update: (cache, { data: data }) => {
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            ...cachedData,
            data: {
              organizations: {
                ...cachedData?.organizations,
                totalCount: cachedData?.organizations?.totalCount
                  ? cachedData.organizations.totalCount + 1
                  : undefined,
                nodes: [
                  ...(cachedData?.organizations?.nodes ?? []),
                  data?.organizationMutation?.createOrganization,
                ],
              },
            },
          });
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
