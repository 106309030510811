


































import Sidebar, { useConfirmDelete } from "@/components/Sidebar.vue";
import {
  computed,
  defineComponent,
  provide,
  reactive,
  ref,
  toRef,
  watch,
} from "@vue/composition-api";
import IMenu from "@/interfaces/IMenu";
import Tapbar from "@/components/Tapbar.vue";
import OrganizationData from "./Components/OrganizationData.vue";
import OrganizationRoles from "./Components/OrganizationRoles.vue";
import OrganizationCreated from "./Components/OrganizationCreated.vue";
import { OrganizationKey, IsNewKey, LogoFileKey } from "./symbols";
import ITabContent from "@/interfaces/ITabContent";
import CreateWizard from "@/components/CreateWizard.vue";
import IStep from "@/interfaces/IStep";
import { Organization, useGetOrganizationQuery } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import useDeleteOrganizationMutation from "./Composables/useDeleteOrganizationMutation";
import useUpdateOrganizationMutation from "./Composables/useUpdateOrganizationMutation";
import useCreateOrganizationMutation from "./Composables/useCreateOrganizationMutation";
import useVuelidate from "@vuelidate/core";
import useProfile from "@/composables/useProfile";
import ChangeLogTable from "@/components/ChangelogTable.vue";
import { EditableKey, IdKey } from "@/symbols";
import useSaveButton from "@/composables/buttons/useSaveButton";
import useCancelButton from "@/composables/buttons/useCancelButton";
import OrganizationApplications from "./Components/OrganizationApplications.vue";
import useGoToParent from "@/composables/useGoToParent";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  components: { Sidebar, Tapbar, CreateWizard },
  setup(props, { root }) {
    const { isCentralAdmin, isTechnicalAdmin } = useProfile();
    const editable = computed(
      () => (isCentralAdmin.value || isTechnicalAdmin.value) ?? false
    );

    const isNew = ref(props.id == null);
    const currentTab = ref(0);
    const hasChanges = ref<boolean | null>(null);
    if (isNew.value) hasChanges.value = false;
    const v$ = useVuelidate({ $autoDirty: true, $lazy: true });

    const organization = reactive<Organization>({
      id: props.id,
      name: "",
      vat: "",
      description: "",
      logoUrl: "",
      organizationSupporters: [],
      organizationContacts: [],
      assigneeRoles: [],
      assignedRoles: [],
      users: {},
    });
    const logoFile = ref<File | null>(null);

    const updateMutation = useUpdateOrganizationMutation(
      organization,
      logoFile
    );
    const createMutation = useCreateOrganizationMutation(
      organization,
      logoFile
    );
    const deleteMutation = useDeleteOrganizationMutation(organization);

    const { result, loading } = useGetOrganizationQuery(
      () => ({ id: props.id }),
      {
        enabled: !isNew.value,
        fetchPolicy: "no-cache",
      }
    );

    provide(OrganizationKey, organization);
    provide(IdKey, toRef(organization, "id"));
    provide(IsNewKey, isNew);
    provide(LogoFileKey, logoFile);
    provide(EditableKey, editable);

    watch(
      () => useResult(result).value,
      (newValue) => {
        Object.assign(organization, newValue?.organization);
        v$.value.$reset();
      },
      { immediate: true, deep: false }
    );

    watch(
      () => [
        organization.name,
        organization.description,
        organization.organizationContacts,
        organization.logoUrl,
        logoFile.value,
      ],
      () => {
        if (loading.value) return;
        if (hasChanges.value == null) {
          hasChanges.value = false;
        } else {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );

    createMutation.onDone(({ data }) => {
      organization.id =
        data?.organizationMutation?.createOrganization?.id ?? "";
      currentTab.value++;
    });

    updateMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    deleteMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    return {
      isNew,
      currentTab,
      hasChanges,
      organization,
      logoFile,
      v$,
      loading: computed(
        () =>
          loading.value ||
          updateMutation.loading.value ||
          createMutation.loading.value ||
          deleteMutation.loading.value
      ),
      menuItems: computed<IMenu[]>(() =>
        isNew.value
          ? []
          : [
              {
                name: root.$tc("organizations.delete"),
                icon: "trash-alt",
                disabled: () =>
                  !editable.value ||
                  useResult(result, null, (data) => data.defaultOrganization)
                    .value?.id == organization.id,
                command: async () => {
                  if (
                    await useConfirmDelete(
                      organization.name ?? "",
                      organization.vat ?? ""
                    )
                  )
                    deleteMutation.call();
                },
              },
            ]
      ),
      tabs: computed<ITabContent[]>(() =>
        isNew.value
          ? [
              {
                title: root.$tc("common.steps.data"),
                primaryButton: {
                  title: root.$tc("organizations.create"),
                  variant: "success",
                  onClick: async () => {
                    if (await v$.value.$validate()) createMutation.call();
                  },
                  disabled:
                    (!hasChanges.value ?? false) ||
                    v$.value.$errors.length > 0 ||
                    v$.value.$pending,
                },
                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
                component: OrganizationData,
              },
              {
                title: root.$tc("common.steps.done"),
                component: OrganizationCreated,
              },
            ]
          : [
              {
                title: root.$tc("common.steps.data"),
                component: OrganizationData,

                primaryButton: {
                  ...useSaveButton(async () => {
                    if (await v$.value.$validate()) updateMutation.call();
                  }),
                  disabled:
                    (!hasChanges.value ?? false) ||
                    v$.value.$errors.length > 0 ||
                    v$.value.$pending,
                },

                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
              },
              {
                title: root.$tc("roles.title"),
                component: OrganizationRoles,
              },
              {
                title: root.$tc("applications.title"),
                component: OrganizationApplications,
                supportsFullscreen: true,
              },
              {
                title: root.$tc("history.title"),
                component: ChangeLogTable,
                visible: isCentralAdmin.value,
              },
            ]
      ),
      steps: computed<IStep[]>(() => [
        {
          title: root.$tc("common.steps.data"),
          icon: "building",
        },
        {
          title: root.$tc("common.steps.done"),
          icon: "check",
        },
      ]),
    };
  },
});
