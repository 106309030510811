import { Organization, useUpdateOrganizationMutation } from "@/graphql/types";
import { Ref } from "@vue/composition-api";

// Cache updates is handled automatically by Apollo
export default function (
  organization: Organization,
  logoFile: Ref<File | null>
) {
  const mutation = useUpdateOrganizationMutation({});

  // Cache handled automatically by Apollo
  function call() {
    mutation.mutate(
      {
        input: {
          id: organization.id,
          name: organization.name ?? "",
          description: organization.description,
          logoUrl: organization.logoUrl,
          logoFile: logoFile.value,
          organizationContacts:
            organization.organizationContacts?.map((element) => {
              return {
                name: element?.name ?? "",
                phone: element?.phone ?? "",
                email: element?.email ?? "",
              };
            }) ?? [],
        },
      },
      {
        context: {
          useMultipart: true,
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
