








































import InputGroup from "@/components/InputGroup.vue";
import Popover from "@/components/Popover.vue";
import { OrganizationContact } from "@/graphql/types";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { required, email, maxLength } from "@/utilities/validators";
import IButton from "@/interfaces/IButton";
import injectStrict from "@/utilities/injectstrict";
import { EditableKey } from "@/symbols";

export default defineComponent({
  components: { Popover, InputGroup },
  props: {
    isCanAddContactSupport: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["createdContact", "editedContact"],
  setup(_, { emit, root }) {
    const editable = injectStrict(EditableKey);

    // Refs
    const show = ref(false);
    const hasChanges = ref<boolean>(false);
    const organizationContact = reactive<OrganizationContact>({
      id: "",
      name: "",
      email: "",
      phone: "",
    });

    const v$ = useVuelidate(
      {
        name: { required, maxLength: maxLength(255) },
        email: { required, email, maxLength: maxLength(255) },
        phone: { maxLength: maxLength(255) },
      },
      {
        ...toRefs(organizationContact),
      },
      { $stopPropagation: true, $autoDirty: true }
    );

    // Functions
    async function addOrUpdateContact() {
      if (!(await v$.value.$validate())) return;
      if (organizationContact.id) {
        emit("editedContact", organizationContact);
      } else {
        emit("createdContact", organizationContact);
      }
      show.value = false;
    }

    watch(
      () => show.value,
      (newValue) => {
        v$.value.$reset();
        hasChanges.value = false;
        // Reset all fields if the popup is closed
        // Note: we should not reset when the popup is openned because we will initialize data in edit mode
        if (newValue == false) {
          Object.assign(organizationContact, {
            id: "",
            name: "",
            email: "",
            phone: "",
          });
        }
      }
    );

    watch(
      () => organizationContact,
      () => {
        if (hasChanges.value === false) hasChanges.value = true;
      },
      { deep: true }
    );

    function openEditPopup(item: OrganizationContact): void {
      Object.assign(organizationContact, item);
      show.value = true;
    }
    return {
      editable,
      show,
      organizationContact,
      addOrUpdateContact,
      v$,
      openEditPopup,
      primaryButton: computed<IButton>(() => {
        return {
          title: organizationContact.id
            ? root.$tc("common.save")
            : root.$tc("common.add"),
          variant: "success",
          onClick: async () => await addOrUpdateContact(),
          disabled:
            (!hasChanges.value ?? false) ||
            v$.value.$errors.length > 0 ||
            v$.value.$pending,
        };
      }),
      secondaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.cancel"),
          variant: "outline-info",
          onClick: () => (show.value = false),
        };
      }),
    };
  },
});
