



























































































import Table from "@/components/Table.vue";
import {
  defineComponent,
  toRef,
  toRefs,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import { BvTableFieldArray } from "bootstrap-vue";
import AddOrganizationContactPopup from "./AddOrganizationContactPopop.vue";
import Dropzone from "@/components/Dropzone.vue";
import { IsNewKey, LogoFileKey, OrganizationKey } from "../symbols";
import injectStrict from "@/utilities/injectstrict";
import {
  OrganizationContact,
  useOrganizationByNameLazyQuery,
  useOrganizationByCvrLazyQuery,
} from "@/graphql/types";
import { Guid } from "guid-typescript";
import useVuelidate from "@vuelidate/core";
import InputGroup from "@/components/InputGroup.vue";
import { required, maxLength, withI18nMessage } from "@/utilities/validators";
import { helpers } from "@vuelidate/validators";
import IMenu from "@/interfaces/IMenu";
import Dropdown from "@/components/Dropdown.vue";
import { EditableKey } from "@/symbols";
import { usePromisable } from "@/utilities/useLazyApolloQuery";
import useEditOption from "@/composables/options/useEditOption";
import useDeleteOption from "@/composables/options/useDeleteOption";
import useImageDimensionValidator from "@/composables/useImageDimensionValidator";
import useImageSizeValidator from "@/composables/useImageSizeValidator";
import IImageConstraint from "@/interfaces/IImageConstraint";
import { getLogoConstraint } from "@/utilities/image";

const { withAsync } = helpers;
const MAX_CONTACTS_ORGANIZATION = 1;
export default defineComponent({
  components: {
    Table,
    AddOrganizationContactPopup,
    Dropzone,
    InputGroup,
    Dropdown,
  },
  setup(_, { root }) {
    // Refs
    const contactPopup =
      ref<InstanceType<typeof AddOrganizationContactPopup>>();
    const isNew = injectStrict(IsNewKey);
    const organization = injectStrict(OrganizationKey);
    const logoFile = injectStrict(LogoFileKey);
    const editable = injectStrict(EditableKey);
    const organizationContacts = toRef(organization, "organizationContacts");
    const isCanAddContactSupport = ref(true);

    const v$ = useVuelidate(
      useValidations(
        uniqueNameValidator,
        uniqueCvrValidator,
        logoDimensionValidator,
        logoSizeValidator
      ),
      {
        ...toRefs(organization),
        logoFile: logoFile,
      } as any,
      { $autoDirty: true }
    );
    const vatWarning = ref("");
    watch(
      () => organization.vat,
      (vat) => {
        if (vat) {
          const isValidCvr = /^[0-9]{8}$/.test(vat);
          vatWarning.value = isValidCvr
            ? ""
            : root.$tc("organizations.warnings.warning_cvr");
        } else {
          vatWarning.value = "";
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => organizationContacts,
      (contacts) => {
        if (
          contacts &&
          contacts.value &&
          contacts.value.length >= MAX_CONTACTS_ORGANIZATION
        ) {
          isCanAddContactSupport.value = false;
          return;
        }
        isCanAddContactSupport.value = true;
      },
      { immediate: true, deep: true }
    );

    function openContactPopup(item: OrganizationContact) {
      contactPopup.value?.openEditPopup(item);
    }

    function addOrganizationContact(item: OrganizationContact) {
      if (
        organizationContacts &&
        organizationContacts.value &&
        organizationContacts.value?.length >= MAX_CONTACTS_ORGANIZATION
      ) {
        console.warn(
          `Only be possible to add ${MAX_CONTACTS_ORGANIZATION} contact person`
        );
        return;
      }
      organizationContacts.value?.push({
        id: Guid.create().toString(),
        name: item.name,
        phone: item.phone,
        email: item.email,
      });
    }

    function updateOrganizationContact(item: OrganizationContact) {
      organizationContacts.value = organizationContacts.value?.map((e) =>
        e?.id === item.id
          ? {
              id: item.id,
              name: item.name,
              phone: item.phone,
              email: item.email,
            }
          : e
      );
    }

    function removeOrganizationContact(item: OrganizationContact) {
      organizationContacts.value =
        organizationContacts.value?.filter(
          (element) => element?.id !== item.id
        ) ?? [];
    }
    const { fetch: fetchUniqueName } = usePromisable(
      useOrganizationByNameLazyQuery(() => ({
        name: organization.name || "",
      }))
    );

    const { fetch: fetchUniqueCVR } = usePromisable(
      useOrganizationByCvrLazyQuery(() => ({
        cvr: organization.vat || "",
      }))
    );

    async function uniqueNameValidator() {
      if (!v$.value.name.$dirty || organization.name == "") return true;
      var result = await fetchUniqueName();
      if (
        result &&
        result.data &&
        result.data.organizationByName != null &&
        result.data.organizationByName.id != organization.id
      )
        return false;
      return true;
    }
    async function uniqueCvrValidator() {
      if (!v$.value.vat.$dirty || organization.vat == "") return true;
      var result = await fetchUniqueCVR();
      if (
        result &&
        result.data &&
        result.data.organizationByCvr != null &&
        result.data.organizationByCvr.id != organization.id
      )
        return false;
      return true;
    }
    const logoConstraint: IImageConstraint = getLogoConstraint();
    async function logoDimensionValidator() {
      return await useImageDimensionValidator(logoFile.value, logoConstraint);
    }
    async function logoSizeValidator() {
      return await useImageSizeValidator(logoFile.value, logoConstraint);
    }
    return {
      isNew,
      organization,
      v$,
      editable,
      organizationContacts,
      fields: useFields(),
      addOrganizationContact,
      updateOrganizationContact,
      removeOrganizationContact,
      openContactPopup,
      contactPopup,
      logoFile,
      vatWarning,
      isCanAddContactSupport,
      options: computed<IMenu<OrganizationContact>[]>(() =>
        editable.value
          ? [
              {
                ...useEditOption(root.$tc("organization.supporter")),
                command: (contact) => {
                  if (contact != null) openContactPopup(contact);
                },
              },
              {
                ...useDeleteOption(root.$tc("organization.supporter")),
                requireConfirmation: false,
                command: (contact) => {
                  if (contact != null) removeOrganizationContact(contact);
                },
              },
            ]
          : []
      ),
    };
  },
});

function useFields(): BvTableFieldArray {
  return [
    {
      key: "name",
    },
    {
      key: "data",
    },
  ];
}

function useValidations(
  uniqueNameValidator: () => Promise<boolean>,
  uniqueCvrValidator: () => Promise<boolean>,
  logoDimemsionValidator: () => Promise<boolean>,
  logoSizeValidator: () => Promise<boolean>
) {
  return {
    name: {
      required,
      maxLength: maxLength(255),
      uniqueName: withAsync(withI18nMessage(uniqueNameValidator)),
    },
    vat: {
      required,
      maxLength: maxLength(255),
      uniqueCvr: withAsync(withI18nMessage(uniqueCvrValidator)),
    },
    description: { maxLength: maxLength(1024) },
    logoFile: {
      logoDimension: withAsync(withI18nMessage(logoDimemsionValidator)),
      maxFileSize: withAsync(withI18nMessage(logoSizeValidator)),
    },
  };
}
