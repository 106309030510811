import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { Organization, useDeleteOrganizationMutation } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function (organization: Organization) {
  const mutation = useDeleteOrganizationMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    if (organization.id == null) return;
    mutation.mutate(
      {
        id: organization.id,
      },
      {
        update: (cache) => {
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            ...cachedData,
            data: {
              ...cachedData,
              organizations: {
                ...cachedData?.organizations,
                totalCount: cachedData?.organizations?.totalCount
                  ? cachedData.organizations.totalCount - 1
                  : undefined,
                nodes:
                  cachedData?.organizations?.nodes?.filter(
                    (element) => element?.id != organization.id
                  ) ?? [],
              },
            },
          });

          // We clear user cache, as some users might have belonged to this organization
          useDeleteFromCache(cache, "users");

          // The organization might have owned applications
          useDeleteFromCache(cache, "applications");
        },
      }
    );
  }
  return {
    ...mutation,
    call,
  };
}
